// store/counter.js
import Vue from 'vue'
import Vuex from 'vuex'

//1.安装插件
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    // 标题
    stotitle: "null",
    // 简介
    briefntroduction: "null",
    id: null,
    // 考古
    // 堆积层次
    hierarchy: "null",
    // 包含物
    inclusion: "null",
    // 造型主要特征
    tezheng: "null",
    // 完整性
    wanzhengxing: "null",
    // 尺寸
    chicun: "null",
    // 颈部
    jingbu: "null",
    // 唇形
    chunxing: "null",
    // 口型
    kouxing: "null",
    // 肩部
    jianbu: "null",
    // 腹部
    fubu: "null",
    // 底足
    dizu: "null",
    // 生活用品
    shyp: "null",
    // 纹饰
    nws: "null",
    // 外纹饰
    wws: "null",
    // 胎料
    tailiao: "null",
    // 材质
    caizhi: "null",
    // 密度
    midu: "null",
    // 厚度
    houdu: "null",
    // 釉料
    zhouzhi: "null",
    ylkey:"null",
    // 施轴情况
    szqk: "null",
    // 高温色釉瓷器
    gwsycq: "null",
    // 釉料
    yl: "null",
    // 彩料
    cailiaolb: "null",
    cailiaolx: "null",
    cailiaofs: "null",
    cailiaoys: "null",
    qtcailiaofs: "null",
    // 内容
    nr: "null",
    wz: "null",
    gy: "null",
    bk: "null",
    pl: "null",
    zt: "null",
    zs: "null",
    img: "null",
    time:"null"
  },

  mutations: {
    // increment(state) {
    //   state.count++;
    // },
    // decrement(state) {
    //   state.count--;
    // }
    setylkey(state, id){
      state.ylkey = id;
    },
    // 改id
    setId(state, id) {
      state.id = id;
      localStorage.setItem("id", id);
    },
    // 改标题
    stotitle(state, id) {
      state.stotitle = id;
    },
    // 改简介
    setbriefntroduction(state, id) {
      state.briefntroduction = id;
    },
    sethierarchy(state, id) {
      state.hierarchy = id;
    },
    setinclusion(state, id) {
      state.inclusion = id;
    },
    settezheng(state, id) {
      state.tezheng = id;
    },
    setwanzhengxing(state, id) {
      state.wanzhengxing = id;
    },
    setchicun(state, id) {
      state.chicun = id;
    },
    setjingbu(state, id) {
      state.jingbu = id;
    },
    setchunxing(state, id) {
      state.chunxing = id;
    },
    setkouxing(state, id) {
      state.kouxing = id;
    },
    setjianbu(state, id) {
      state.jianbu = id;
    },
    setfubu(state, id) {
      state.fubu = id;
    },
    setdizu(state, id) {
      state.dizu = id;
    },
    setshyp(state, id) {
      state.shyp = id;
    },
    setnws(state, id) {
      state.nws = id;
    },
    swtwws(state, id) {
      state.wws = id;
    },
    settailiao(state, id) {
      state.tailiao = id;
    },
    setcaizhi(state, id) {
      state.caizhi = id;
    },
    setmidu(state, id) {
      state.midu = id;
    },
    sethoudu(state, id) {
      state.houdu = id;
    },
    setzhouzhi(state, id) {
      state.zhouzhi = id;
    },
    setszqk(state, id) {
      state.szqk = id;
    },
    setgwsycq(state, id) {
      state.gwsycq = id;
    },
    setyl(state, id) {
      state.yl = id;
    },
    setcailiaolb(state, id) {
      state.cailiaolb = id;
    },

    setcailiaolx(state, id) {
      state.cailiaolx = id;
    },
    setcailiaofs(state, id) {
      state.cailiaofs = id;
    },
    setcailiaoys(state, id) {
      state.cailiaoys = id;
    },

    setqtcailiaofs(state, id) {
      state.qtcailiaofs = id;
    },
    setnr(state, id) {
      state.nr = id;
    },
    setwz(state, id) {
      state.wz = id;
    },
    setgy(state, id) {
      state.gy = id;
    },
    setbk(state, id) {
      state.bk = id;
    },
    setpl(state, id) {
      state.pl = id;
    },
    setzt(state, id) {
      state.zt = id;
    },
    setzs(state, id) {
      state.zs = id;
    },
    setimg(state, id) {
      state.img = id;
    },
    settime(state, id) {
      state.time = id;
    },
 





  },

  actions: {
    updateId({ commit }, id) {
         console.log(Object.prototype.toString.call(id).slice(8, -1).toLowerCase());
      commit('setId', id);
      if (id == 1) {
        commit('stotitle', "成化斗彩海水异兽纹盘");
        commit('setbriefntroduction', "口微侈，尖圆唇，曲腹较浅，隐圈足。白胎细腻，胎体坚致，较薄。白釉光亮。通体施釉，足端刮釉。整体纹饰以青花勾绘轮廓线，纹饰填以绿彩，辅以黄彩及红彩。外壁近口沿处绘一周弦纹，腹部勾绘海水异兽，间以祥云，近足处绘海水纹。内底单圈内勾绘一怪兽，双角、两翼、鹿头、狮尾、鳞身、羊蹄。外底书双框双行“大明成化年製”六字楷书款。青花发色较鲜艳，呈青蓝色。整体彩绘发色鲜艳。");
        commit('sethierarchy', "口微侈，尖圆唇，曲腹较浅，隐圈足。白胎细腻，胎体坚致，较薄。白釉光亮。通体施釉，足端刮釉。整体纹饰以青花勾绘轮廓线，纹饰填以绿彩，辅以黄彩及红彩。外壁近口沿处绘一周弦纹，腹部勾绘海水异兽，间以祥云，近足处绘海水纹。内底单圈内勾绘一怪兽，双角、两翼、鹿头、狮尾、鳞身、羊蹄。外底书双框双行“大明成化年製”六字楷书款。青花发色较鲜艳，呈青蓝色。整体彩绘发色鲜艳。");
        commit('setinclusion', "包含物主要为明清瓷片及窑具。民窑瓷片略多于官窑。民窑瓷片种类以青花为主，青釉、白釉次之，少量彩瓷、颜色釉。官窑瓷片种类以青花、白釉为主，涩胎次之，少量彩瓷、颜色釉。器型均以碗、盘为主。官窑瓷器可见年款的以光绪为主，嘉靖、同治次之，少量正德、弘治、成化、康熙、乾隆年款。窑具以匣钵、套钵、垫饼及各类支具为主。");
        commit('settezheng', "侈口曲腹隐圈足盘");
        commit('setwanzhengxing', "可复原");
        commit('setchicun', "口径：18.2 底径：9.6 高：3.8");
        commit('setjingbu', "/");
        commit('setchunxing', "尖圆唇");
        commit('setkouxing', "口微侈");
        commit('setjianbu', "/");
        commit('setfubu', "曲腹");
        commit('setdizu', "隐圈足");
        commit('setshyp', "盘");
        // 纹饰
        commit('setnws', "整体纹饰以青花勾绘，纹饰填以绿彩，辅以黄彩及红彩。内底单圈内勾绘异兽云纹。");
        commit('swtwws', "整体纹饰以青花勾绘，纹饰填以绿彩，辅以黄彩及红彩。外壁近口沿处绘一周弦纹，腹部勾绘海水异兽云纹，近底处绘一周弦纹。");
        commit('settailiao', "白胎");
        commit('setcaizhi', "细腻");
        commit('setmidu', "坚致");
        commit('sethoudu', "较薄");
        commit('setzhouzhi', "白釉、光亮");
        commit('setszqk', "通体施釉，足端刮釉");
        commit('setgwsycq', "/");
        commit('setyl', "/");
        commit('setcailiaolb', "釉上釉下结合彩");
        commit('setcailiaolx', "斗彩");
        commit('setcailiaofs', "鲜艳");
        commit('setcailiaoys', "青蓝");
        commit('setqtcailiaofs', "鲜艳");
        commit('setnr', "大明成化年製");
        commit('setwz', "外底");
        commit('setgy', "以青花书");
        commit('setbk', "双框");
        commit('setpl', "双行");
        commit('setzt', "楷书款");
        commit('setzs', "六字");
        commit('setimg', "1");
        commit('setylkey', "Y13");
        commit('settime', "约 1465-1487");

        








      }

      if (id == 2) {
        commit('stotitle', "永乐白釉爵杯");
        commit('setbriefntroduction', "爵口若舟形，口沿上立一对短柱，流短而圆，尾较尖长，腹圆而浅，底似釜，下有三足。腹一侧有一半圆形鋬。白釉光亮。通体施釉，底部无釉。外壁腹部模印凸弦纹一周。");
        commit('sethierarchy', "土色红褐，质地较松。厚45—170厘米，深80—215厘米。属宣德时期原生窑业堆积层。");
        commit('setinclusion', "包含物主要分为上、下两层。上层夹杂极少量的细碎瓷片、炭屑及红砖屑；下层为以大量匣钵、垫饼等为主的堆积。瓷片数量较少，以宣德时期为主，种类以白釉为主，亦可见青花、仿哥釉、青釉、黄釉、孔雀绿、祭红、黑釉等。器型有盘、碟、高足碗、高足杯等。");
        commit('settezheng', "爵杯");
        commit('setwanzhengxing', "不可复原异形件");
        commit('setchicun', "/");
        commit('setjingbu', "/");
        commit('setchunxing', "/");
        commit('setkouxing', "敞口");
        commit('setjianbu', "/");
        commit('setfubu', "曲腹");
        commit('setdizu', "小平底，下接三柱状足");
        commit('setshyp', "盘杯");
        // 纹饰
        commit('setnws', "/");
        commit('swtwws', "外壁腹部模印凸弦纹一周");
        commit('setshyp', "盘杯");
        commit('settailiao', "白胎");
        commit('setcaizhi', "细腻");
        commit('setmidu', "坚致");
        commit('sethoudu', "较薄");
        commit('setzhouzhi', "白釉、光亮");
        commit('setszqk', "通体施釉，底部及足端无釉");
        commit('setgwsycq', "/");
        commit('setyl', "/");
        commit('setcailiaolb', "/");
        commit('setcailiaolx', "/");
        commit('setcailiaofs', "/");
        commit('setcailiaoys', "/");
        commit('setqtcailiaofs', "/");
        commit('setnr', "/");
        commit('setwz', "/");
        commit('setgy', "/");
        commit('setbk', "/");
        commit('setpl', "/");
        commit('setzt', "/");
        commit('setzs', "/");
        commit('setimg', "null");
        commit('setylkey', "Y62");
        commit('settime', "约 1403-1424");

      }
      if (id == 3) {
        commit('stotitle', "正德青花侈口曲腹碗");
        commit('setbriefntroduction', "侈口，圆唇，深曲腹，圈足。白胎细腻，胎体坚致，较薄。白釉光亮。通体施釉，足端刮釉。内外壁近口沿处各绘两周弦纹，内外壁及内底双圈内均绘穿花龙纹。足墙处绘如意云头纹。外底书双圈双行“正德年製”四字楷书款。青花发色鲜艳，呈青蓝色。");
        commit('sethierarchy', "土质疏松。厚0-40厘米，深25厘米。属二次搬运堆积层。");
        commit('setinclusion', "包含物主要为官窑与民窑的大块直筒形及漏斗形匣钵、套钵、垫饼、窑渣、废窑砖等。种类有白釉、白釉涩胎龙纹、素胎刻纹，另有黄釉、孔雀绿釉、蓝地紫釉等。器型以碗盘为主。");
        commit('settezheng', "侈口曲腹圈足碗");
        commit('setwanzhengxing', "带口部残片");
        commit('setchicun', "高：5.4 口径：10.7 底径：4.0");
        commit('setjingbu', "/");
        commit('setchunxing', "尖圆唇");
        commit('setkouxing', "侈口");
        commit('setjianbu', "/");
        commit('setfubu', "曲腹");
        commit('setdizu', "圈足");
        commit('setshyp', "碗");
        // 纹饰
        commit('setnws', "内壁近口沿处绘两周弦纹，腹部绘龙穿花纹。");
        commit('swtwws', "外壁近口沿处绘两周弦纹，腹部绘龙穿花纹。");
        commit('settailiao', "白胎");
        commit('setcaizhi', "细腻");
        commit('setmidu', "坚致");
        commit('sethoudu', "较薄");
        commit('setzhouzhi', "白釉微泛青、光亮");
        commit('setszqk', "内外壁均施釉");
        commit('setgwsycq', "/");
        commit('setyl', "/");
        commit('setcailiaolb', "釉下彩");
        commit('setcailiaolx', "青花");
        commit('setcailiaofs', "鲜艳");
        commit('setcailiaoys', "青蓝");
        commit('setqtcailiaofs', "/");
        commit('setnr', "正德年製");
        commit('setwz', "外底");
        commit('setgy', "以青花书");
        commit('setbk', "双圈");
        commit('setpl', "双行");
        commit('setzt', "楷书款");
        commit('setzs', "四字");
        commit('setimg', "3");
        commit('setylkey', "Y181");
        commit('settime', "约 1956-1521");

      }
      if (id == 4) {
        commit('stotitle', "成化仿官釉侈口长颈瓶");
        commit('setbriefntroduction', "侈口，细长颈，溜肩，扁圆腹，圈足。青釉光亮，布满开片。通体施釉，足端刮釉。外底书双框双行'大明成化年製'六字楷书款。");
        commit('sethierarchy', "厚5-25厘米。瓷片堆积由斜坡向下逐渐增厚。");
        commit('setinclusion', "包含物主要为大量的成化官窑瓷片。种类有青花、釉里红、青釉、白釉、黑釉、祭蓝、祭红、洒蓝、黄釉、孔雀绿釉、蓝地白花、斗彩、白地绿彩、白地黄彩、白地红绿彩、青花地矾红彩、黄地绿彩、素三彩等。器型主要为青花类的碗、盘、罐、高足杯、鹤颈瓶、齐箸碟、大盒、鼓钉三足炉等；仿官青釉类的觚、长颈瓶、贯耳瓶、铺首衔环耳瓶、方炉、三足炉、海棠式花盆、渣斗式花盆、鼓钉盆托、水盂、盘等；另有大量斗彩与红绿彩半成品。多数器物以青花书双圈或双框“大明成化年製”六字款。");
        commit('settezheng', "侈口长颈鼓腹圈足瓶");
        commit('setwanzhengxing', "带口部残片");
        commit('setchicun', "高：22.1 口径：6.5 底径：7.3");
        commit('setjingbu', "长颈");
        commit('setchunxing', "/");
        commit('setkouxing', "侈口");
        commit('setjianbu', "溜肩");
        commit('setfubu', "鼓腹");
        commit('setdizu', "圈足");
        commit('setshyp', "瓶");
        commit('setnws', "/");
        commit('swtwws', "/");
        commit('settailiao', "灰胎");
        commit('setcaizhi', "细腻");
        commit('setmidu', "坚致");
        commit('sethoudu', "较薄");
        commit('setzhouzhi', "光亮、有细碎开片");
        commit('setszqk', "内外壁均施釉");
        commit('setgwsycq', "仿官釉");
        commit('setyl', "/");
        commit('setcailiaolb', "/");
        commit('setcailiaolx', "/");
        commit('setcailiaofs', "/");
        commit('setcailiaoys', "/");
        commit('setqtcailiaofs', "/");
        commit('setnr', "大明成化年製");
        commit('setwz', "外底");
        commit('setgy', "以青花书");
        commit('setbk', "双框");
        commit('setpl', "双行");
        commit('setzt', "楷书款");
        commit('setzs', "六字");
        commit('setimg', "4");
        commit('setylkey', "Y381");
        commit('settime', "约 1465-1487");

      }
      if (id == 5) {
        commit('stotitle', "宣德孔雀绿釉侈口曲腹盘");
        commit('setbriefntroduction', "侈口，细长颈，溜肩，扁圆腹，圈足。青釉光亮，布满开片。通体施釉，足端刮釉。外底书双框双行'大明成化年製'六字楷书款。");
        commit('sethierarchy', "土色黑，土质上紧下松。厚7-40厘米。推测该层是与Q12有密切关系的一个活动面");
        commit('setinclusion', "包含物主要为覆烧垫圈、细碎的漏斗状匣钵片、少量窑渣等。瓷片有宣德白釉碗、宣德红釉、宣德青花鸟食罐、成化青花及成化斗彩数片，其余大都为宋元青白瓷盘碗等。");
        commit('settezheng', "侈口曲腹圈足盘");
        commit('setwanzhengxing', "带口部残片");
        commit('setchicun', "口径：18.0 底径：11.3");
        commit('setjingbu', "/");
        commit('setchunxing', "圆唇");
        commit('setkouxing', "侈口");
        commit('setjianbu', "/");
        commit('setfubu', "曲腹");
        commit('setdizu', "圈足");
        commit('setshyp', "盘");
        commit('setnws', "/");
        commit('swtwws', "/");
        commit('settailiao', "白胎");
        commit('setcaizhi', "细腻");
        commit('setmidu', "坚致");
        commit('sethoudu', "较薄");
        commit('setzhouzhi', "光润、白釉、光亮、有细碎开片");
        commit('setszqk', "外壁施孔雀绿釉，内壁施白釉");
        commit('setgwsycq', "/");
        commit('setyl', "孔雀绿釉");
        commit('setcailiaolb', "/");
        commit('setcailiaolx', "/");
        commit('setcailiaofs', "/");
        commit('setcailiaoys', "/");
        commit('setqtcailiaofs', "/");
        commit('setnr', "大明宣德年製");
        commit('setwz', "外底");
        commit('setgy', "以青花书");
        commit('setbk', "双圈");
        commit('setpl', "双行");
        commit('setzt', "楷书款");
        commit('setzs', "六字");
        commit('setimg', "5");
        commit('setylkey', "Y402");
        commit('settime', "约 1426-1435");

      }
      console.log("store", id)

    },
    // increment(context) {
    //   context.commit('increment');
    // },
    // decrement(context) {
    //   context.commit('decrement');
    // }
  },

  getters: {
    getId: state => state.id,
    // doubleCount(state) {
    //   return state.count * 2;
    // }
  }
})

