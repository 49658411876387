<template>
  <div class="content">
    <!-- 背景 -->
    <div class="index_bcl">
      <img src="../imager/data/backgound.jpg" mode="">
    </div>
    <!-- 内容太 -->
    <div class="ceneten">
      <!-- 基因数据 -->
      <div class="gendata">
        <img src="../imager/data/title.png" alt="">
      </div>
      <!-- 滑动 -->
      <div class="slide">
        <img src="../imager/data/huadong.png" alt="">
      </div>
      <!-- 触摸 -->
      <div class="right_box">
        <v-touch @swipeup="swiperup" @swipedown="swiperdown"
          style="width: 100%; height: 100%; display: flex; align-items: center; z-index: 9999;">
          <div class="wsw">
            <img src="../imager/data/di.png" alt="">
          </div>
          <!-- <div class="wsw2">
            <img src="../imager/data/dongfore.png" />
          </div> -->
          <div class="wsw3">
            <img src="../imager/data/dongthree.png" />
          </div>
          <div class="wsw4">
            <img src="../imager/data/dongtwo.png" />
          </div>
          <div class="overall">
            <div class="circle-box">
              <div class="circle">
                <div class="origin" :style="`transform: rotate(${stard}deg);`">
                  <div :style="`;transform: rotate(${-stard}deg);`" class="img-box" v-for="(i, index) in boxNum"
                    :key="index" @click="Turn(index)">
                    <div class="box">
                      <div class="content" v-if="index + 1 == 1">
                        考古
                      </div>
                      <div class="content" v-if="index + 1 == 2">
                        造型
                      </div>
                      <div class="content" v-if="index + 1 == 3">
                        纹饰
                      </div>
                      <div class="content" v-if="index + 1 == 4">
                        胎料
                      </div>
                      <div class="content" v-if="index + 1 == 5">
                        釉料
                      </div>
                      <div class="content" v-if="index + 1 == 6">
                        彩料
                      </div>
                      <div class="content" v-if="index + 1 == 7">
                        款识
                      </div>
                      <div class="content" v-if="index + 1 == 8">
                        装烧
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="photos">
            <img src="../imager/data/dongone.png" />
          </div>
        </v-touch>
      </div>
      <!-- 触摸 -->
      <!-- 左边图片 -->
      <div class="left_ce">
				<img v-if="this.pathurl=='Y13'" class="img1" src="../imager/backups/CEY13.png" alt="">
        <img v-else="this.pathurl=='Y13'" class="img2" src="../imager/backups/y62.png" alt="">

      </div>
      <!-- 底部栏 -->
      <Bttom></Bttom>
    </div>
    <!-- 考古 -->
    <Kaogu v-if="this.isKaogu" @numChange="getNewCount"></Kaogu>
    <ZaoXing v-if="this.isZaoXing" @numChange="getNewCount2"></ZaoXing>
    <lines v-if="this.islines" @numChange="getNewCount3"></lines>
    <material v-if="this.ismaterial" @numChange="getNewCount4"></material>
    <glaze v-if="this.isglaze" @numChange="getNewCount5"></glaze>
    <color v-if="this.iscolor" @numChange="getNewCount6"></color>
    <insc v-if="this.isinscriptions" @numChange="getNewCount7"></insc>
    <firing v-if="this.isfiring" @numChange="getNewCount8"></firing>

  </div>
</template>

<script>
import Bttom from '@/components/bottom.vue'
import Kaogu from '@/components/kaogu.vue'
import ZaoXing from '@/components/profiling.vue'
import lines  from '@/components/lines.vue'
import material from '@/components/material.vue'
import glaze from '@/components/glaze.vue'
import color from '@/components/color.vue'
import insc from '@/components/inscriptions.vue'
import firing from '@/components/firing.vue'
export default {
  components: {
    Bttom, Kaogu,ZaoXing,lines,material,glaze,color,insc,firing
  },
  data() {
    return {
      circle_w: 450, //圆盘的宽
      circle_h: 450, //圆盘的高
      box_w: 350, //圆盘上覆盖的小圆点宽
      box_h: 350, //圆盘上覆盖的小圆点高
      PI: 500, //分布角度，默认为360deg
      stard: 145, //起始角度
      stard_s: null, //用来默认储存第一个初始值
      boxNum: 8, //圆盘上覆盖的小圆点个数
      activeIndex: null, //默认下标
      name: "touch",
      Index: null,
      a: 0,
      b: 4,
      c: 0,
      // 考古开关
      isKaogu: false,
      btnc: 0,
      isZaoXing:false,
      islines:false,
      ismaterial:false,
      isglaze:false,
      iscolor:false,
      isinscriptions:false,
      isfiring:false,
      pathurl: "",
    }
  },
  created() {
    this.stard_s = this.stard;
  },
  mounted() {
    let ids = localStorage.getItem("id");
		console.log(ids)
		if (ids == 1) {
			this.pathurl = "Y13"
		}
		if (ids == 2) {
			this.pathurl = "n1"
		}
		if (ids == 3) {
			this.pathurl = "Y181"
		}
		if (ids == 4) {
			this.pathurl = "Y381"
		}
		if (ids == 5) {
			this.pathurl = "Y402"
		}
    this.init();
    this.Turn(this.activeIndex);
  },
  onLoad() {

  },
  methods: {

    //初始化小圆点，根据计算使其分布到对应位置
    init() {
      let box = document.querySelectorAll(".img-box");
      let avd = this.PI / box.length / 2; //每一个 img-box 对应的角度
      let thAT = this
      let ahd = (avd * Math.PI) / 180; //每一个 img-box 对应的弧度
      let radius = this.circle_w / 2; //圆的半径
      for (let i = 0; i < box.length; i++) {
        box[i].style.left = Math.sin(ahd * i) * radius * (100 / 750) + "vw";
        box[i].style.top = Math.cos(ahd * i) * radius * (100 / 750) + "vw";
      }

      // let ahd = (avd * Math.PI) / 180; //每一个 img-box 对应的弧度
      // let radius = this.circle_w / 2; //圆的半径
      // for (let i = 0; i < box.length; i++) {
      //    box[i].style.left = Math.sin(ahd * i) * radius + "px";
      //   box[i].style.top = Math.cos(ahd * i) * radius + "px";

      // }
    },
    //点击相对应小圆点，圆盘进行相对应角度的转动
    Turn(index) {
      this.btnc += 1
      console.log(this.btnc)
      // 显示考古
      if (index == 0 && this.btnc !== 1) {
        this.isKaogu = true
        console.log(this.isKaogu)
      }
      
      if (index == 1 && this.btnc !== 1) {
        this.isZaoXing = true
      }
      if (index == 2 && this.btnc !== 1) {
        this.islines = true
      }
      if (index ==3 && this.btnc !== 1) {
        this.ismaterial = true
      }
      if (index ==4 && this.btnc !== 1) {
        this.isglaze = true
      }
      if (index ==5 && this.btnc !== 1) {
        this.iscolor = true
      }
      if (index ==6 && this.btnc !== 1) {
        this.isinscriptions = true
      }
      if (index ==7 && this.btnc !== 1) {
        this.isfiring = true
      }
      console.log(index, "11111111111111111")
      let bx = document.querySelectorAll(".box");
      for (let i = 0; i < bx.length; i++) {
        if (i == index) {
          bx[i].classList.add("box-active");
        } else {
          bx[i].classList.remove("box-active");
        }
      }
      if (this.c == 0) {

        let _this = this;
        this.Index = index;
        _this.stard = index * (_this.PI / _this.boxNum) + _this.stard_s;
        console.log(_this.stard)

      }
      this.c = 1
    },
    btn() {
      let _this = this;
      console.log(`output->`)
      let bx = document.querySelectorAll(".box");
      // _this.stard = index * (_this.PI / _this.boxNum) + _this.stard_s;
      // console.log(_this.stard)
      _this.stard = 140
      console.log(_this.stard)
      for (let i = 0; i < bx.length; i++) {
        if (i == 1) {
          bx[i].classList.add("box-active");
        } else {
          bx[i].classList.remove("box-active");
        }
      }
    },
    swiperup: function () {
      this.a++
      let _this = this;
      if (this.a >= 4) {
        this.a = 3
      }
      console.log(this.a)
      // if(this.Index!==0){
      //   this.a=this.Index
      // }
      let index = this.Index + this.a

      let bx = document.querySelectorAll(".box");
      if (_this.stard < 270) {
        _this.stard = index * (_this.PI / _this.boxNum) + _this.stard_s;

      }
      console.log(_this.stard)
      // for (let i = 0; i < bx.length; i++) {
      //   if (i == index) {
      //     bx[i].classList.add("box-active");
      //   } else {
      //     bx[i].classList.remove("box-active");
      //   }
      // }

    },
    swiperdown: function () {
      this.a--
      if (this.a <= 0) {
        this.a = 0
      }
      let index = this.a
      let _this = this;
      let bx = document.querySelectorAll(".box");
      _this.stard = index * (_this.PI / _this.boxNum) + _this.stard_s;
      console.log(_this.stard)


      // for (let i = 0; i < bx.length; i++) {
      //   if (i == index) {
      //     bx[i].classList.add("box-active");
      //   } else {
      //     bx[i].classList.remove("box-active");
      //   }
      // }
    },
    getNewCount(val) {
      this.isKaogu = val
    },
    getNewCount2(val) {
      this.isZaoXing = val
    },
    getNewCount3(val) {
      this.islines = val
    },
    getNewCount4(val) {
      this.ismaterial = val
    },
    getNewCount5(val) {
      this.isglaze = val
    },
    getNewCount6(val) {
      this.iscolor = val
    },
    getNewCount7(val) {
      this.isinscriptions = val
    },
    getNewCount8(val) {
      this.isfiring = val
    }



  }
}
</script>

<style lang="scss" scoped>
#app {
  overflow: hidden;
}

* {
  margin: 0;
  padding: 0;
}

.content {
  width: 100%;
  min-height: 100vh;
}

.index_bcl {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  img {
    width: 100%;
    height: 100vh;
  }
}

// 底部栏
.ceneten {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
}

// 基因数据
.gendata {
  position: absolute;
  top: 75px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;

  img {
    width: 368px;
    height: 100%;
  }
}

//  滑动
.slide {
  position: absolute;
  top: 160px;
  width: 100%;
  // height: 23px;
  transform: scale(1.3);
  display: flex;
  justify-content: center;

  img {
    width: 270px;
    height: 100%;
  }
}

// 左边图片
.left_ce {
  width: 100%;
  height: 100vh;
  // width: 328px;
  // height: 645px;
  position: absolute;
  // top: 306px;
  display: flex;
  align-items: center;
  pointer-events: none;
  .img1{
    height:38vw;
	margin-left: -280px;
  transform: rotateX(360deg);
  }
  .img2 {
    height:67vw;
	margin-left: -220px;
  transform: rotateX(360deg);
  }
}


// 新加的
.right_box {
  width: 700px;
  height: 100%;
  position: absolute;
  right: -45px;
}

.overall {
  width: 700px;
  height: 950px;
  position: relative;
  right: -270px;
  top: 110px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.circle-box {
  scale: 2.3;
  position: relative; //注释--------------------------此处显示全圆
  // overflow: hidden; //注释----------------------此处显示全圆
  //   right: -165px; //注释---------------------此处显示全圆
  //   width: 100%;
  //   height: 100%;
  // top: 150px;

  .circle {
    width: 450px;
    height: 450px;
    transform: scale(0.9);
    // width: 100%;
    // height: 100%;
    border-radius: 50%;
    box-sizing: border-box;

    // border: 3px solid #5bbbe7;
    // background: url('../img/圆环底.png') no-repeat;
    // background-size: 100%;
    // background-position: -1px,1px;
    // background: position x -10px;;
    position: relative;
    left: 95px;
    top: -55px;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-left: 50%; //注释----------------此处显示全圆

    .origin {
      width: 350px;
      height: 350px;
      position: relative;
      transition: 0.5s; //控制圆盘的的旋转速率

      div {
        width: 350px;
        height: 350px;
      }

      .img-box {
        user-select: none;
        position: absolute;
        top: 0;
        left: 0;
        transition: none !important;
        pointer-events: none;

        .box {
          overflow: hidden;
          pointer-events: all !important;
          width: 100%;
          height: 100%;
          transition: 0.3s;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 50%;
          transform: scale(0.21);
          cursor: pointer;
          color: white;
          font-size: 40px;
          //   background: black;
          background: url('../imager/data/behavior.png') no-repeat;
          background-size: 100%;
          // overflow: hidden;

          // &:hover {
          //   transform: scale(0.3);
          // }

          //   &:hover .content {
          //     opacity: 1;
          //   }

          .content {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 1;
            font-size: 8.66667vw;
            // padding-left: 7.33333vw;
            color: #54c6de;
            font-weight: 550;
          }
        }

        .box-active {
          //   transition-delay: 0.5s;
          transform: scale(0.23);
          background: url('../imager/data/active.png') no-repeat;
          background-size: 100%;

          .content {
            opacity: 1;
            color: #b5ecff;
            font-size: 8vw;
          }
        }
      }
    }
  }
}

.top {
  position: relative;
  top: 0;
  z-index: 999;
}

.photos {
  pointer-events: none;
  width: 1050px;
  height: 1080px;
  position: absolute;
  // top: 100px;
  right: -600px;
  animation: rotate 5s linear infinite;
  transform: scale(1.1);

  img {
    width: 100%;
    height: 100%;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

}

.wsw {
  width: 1031px;
  height: 1101px;
  position: absolute;
  // top: 110px;
  right: -590px;
  transform: scale(1.1);

  img {
    width: 100%;
    height: 100%;
  }
}

.wsw2 {
  width: 140.13333vw;
  height: 146.46667vw;
  position: absolute;
  right: -81vw;
  transform: scale(1.1);
  animation: rotate 5s linear infinite;


  img {
    width: 100%;
    height: 100%;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.wsw3 {
  width: 1051px;
  height: 1001px;
  position: absolute;
  // top: 300px;
  right: -590px;
  animation: rotate 10s linear infinite;
  transform: scale(1.1);

  img {
    width: 100%;
    height: 100%;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.wsw4 {
  width: 1001px;
  height: 144vw;
  position: absolute;
  // top: 125px;
  right: -570px;
  // animation: rotate 5s linear infinite;
  transform: scale(1.1);

  img {
    width: 100%;
    height: 100%;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
