<template>
  <div id="app" >
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <keep-alive>
    <router-view></router-view>
</keep-alive>
    <!-- 横屏 -->
    <div :class="this.cover" style="">请竖屏观看</div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      // 检测是否横屏
      cover: 'cover',
    }
  },
  created() {
    let that = this
    window.addEventListener(
      'orientationchange',
      function () {
        // Announce the new orientation number
        console.log(window.orientation, 'ssss')
        if (window.orientation == 90 || window.orientation == -90) {
          that.cover = 'coverblock'
        } else {
           let id=localStorage.getItem("id");
       console.log(id,"id")

          this.location.href = `/#/id=${id}`
          that.cover = 'cover'
          location.reload()
          // console.log(this.location)


        }
      },
      false
    )
    console.log(this.cover)
    // 禁止页面缩放
    window.onload = function () {
      document.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
          event.preventDefault()
        }
      })
      document.addEventListener('gesturestart', function (event) {
        event.preventDefault()
      })
    }
  },
  methods: {


  }, mounted() {

  },



}
</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
}

body {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
}

#app {
  overflow: hidden !important;

}



// 横屏
.cover {
  position: fixed;
  z-index: 9999999;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  color: #afafaf;
  font-size: 24px;
  display: none;
  line-height: 100vh;
}

.coverblock {
  position: absolute;
  z-index: 99999999999;
  top: 0;
  left: 0;
  display: flex;
  // align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.9);
  color: #afafaf;
  font-size: 24px;
  line-height: 100vh;
}
</style>

<style>
[v-cloak] {
  display: none;
}
</style>