<template>
    <div class="kaogu_box">
        <div class="centent">
            <!--内容区域 -->
            <div class="centent_boxtitle">
                釉料
            </div>
            <div class="centent_box">
                <div class="box_centent">
                    <!-- 基本信息 -->
                    <div class="information">
                        <div class="title">
                            陶瓷釉料组成
                        </div>
                        <!-- <div v-if="ylkey == 'Y13'" class="information imgcenter">
                            <img src="../imager/backups/y13.png" alt="">
                        </div>
                        <div v-if="ylkey == 'Y62'" class="information imgcenter">
                            <img src="../imager/backups/ks62.png" alt="">
                        </div>
                        <div v-if="ylkey == 'Y181'" class="information imgcenter">
                            <img src="../imager/backups/y181.png" alt="">
                        </div>
                        <div v-if="ylkey == 'Y381'" class="information imgcenter">
                            <img src="../imager/backups/y381.png" alt="">
                        </div>
                        <div v-if="ylkey == 'Y402'" class="information imgcenter">
                            <img src="../imager/backups/y402.png" alt="">
                        </div> -->
                        <table border="1">
                            <tr>
                                <td class="left">釉质</td>
                                <td class="left">{{ zhouzhi }}</td>
                                <td class="left">施釉情况</td>
                                <td class="left "> {{ szqk }}</td>
                            </tr>
                            <tr>
                                <td class="left">高温色釉瓷器</td>
                                <td class="left "> {{ gwsycq }}</td>
                                <td class="left">低温色釉瓷器</td>
                                <td class="left "> {{ yl }}</td>
                            </tr>
                        </table>
                        <!-- <div class="title">
                            陶瓷釉料组成
                        </div>
                        <div class="information imgcenter">
                            <img src="../imager/backups/4.png" alt="">
                        </div> -->

                    </div>

                    <!-- <div class="information2">
                        <div class="title">
                            考古基本信息
                        </div>
                         <div class="look_img">
                            <div class="img_box" v-for="(item, index) in imgdata" :key="index">
                                <img class="imgone" :src="item.img" alt="">
                            </div>
                        </div>

                    </div> -->
                    <div class="material_new">
                        <div class="titlebug">
                            体视显微镜
                        </div>
                        <div class="information imgcenter">
                            <img src="../imager/backups/2.png" alt="">
                        </div>
                        <div class="titlebug" style="margin-top: 30px;">
                            SEM-EDS
                        </div>
                        <!-- 30 -->

                        <div class="information imgcenter" style="margin-top: 40px !important;">
                            <img src="../imager/backups/Y13/1.jpg" alt="">
                        </div>
                        <div class="three">
                            <p>&nbsp;x30&nbsp;</p>
                        </div>
                        <!-- 500 -->

                        <div class="information imgcenter" style="margin-top: 40px !important;">
                            <img src="../imager/backups/Y13/500.jpg" alt="">
                        </div>
                        <div class="three">
                            <p>&nbsp;x500&nbsp;</p>
                        </div>
                        <!-- 拉曼光谱 -->
                       

                        
                        <div class="information imgcenter" style="margin-top: 40px !important;">
                            <img src="../imager/backups/Y13/3.jpg" alt="">
                        </div>
                        <div class="three" style="margin-top: 20px;">
                            <p>&nbsp;分布图总数谱图&nbsp;</p>
                        </div>
                        <!-- 2拉曼光 -->
                        <div class="titlebug" style="margin-top: 20px;">
                            拉曼光谱仪
                        </div>
                        <!-- 30 -->

                        <div class="information imgcenter" style="margin-top: 20px !important;">
                            <img src="../imager/backups/Y13/y1.jpg" alt="">
                        </div>
                        <div class="three">
                            <p>&nbsp;胎图&nbsp;</p>
                        </div>

                        <div class="information imgcenter" style="margin-top: 0 !important;">
                            <img src="../imager/backups/Y13/y2.jpg" alt="">
                        </div>
                        <div class="three">
                            <p>&nbsp;胎&nbsp;</p>
                        </div>
                        <!-- 3射线荧光分析仪 -->
                        <div class="titlebug" style="margin-top: 40px;">
                            X射线荧光分析仪
                        </div>
                        <div class="information imgcenter" style="margin-top: 20px !important;">
                            <img src="../imager/backups/Y13/6.png" alt="">
                        </div>
                    </div>

                    <!-- 结束 -->
                </div>
            </div>
            <!-- 遮罩 -->
            <div class="zhezhao">
                <img src="../imager/data/zhezhao.png" alt="">
            </div>
            <!-- 结束按钮 -->
            <div class="finish" @click="btnblock">
                <img src="../imager/data/delete.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
export default {
    data() {
        return {
            imgdata: [
                { img: require("../imager/test/1.png") },
                { img: require("../imager/test/2.png") },
                { img: require("../imager/test/3.png") },
                { img: require("../imager/test/4.png") }
            ],
            count: false
        };
    },
    computed: {
        ...mapState(['zhouzhi']),
        ...mapState(['szqk']),
        ...mapState(['gwsycq']),
        ...mapState(['yl']),
        ...mapState(['ylkey']),



    },
    mounted() {

    },

    methods: {
        btnblock() {
            console.log("我被触发了")
            this.$emit('numChange', this.count)
        }
    },
};
</script>

<style lang="scss" scoped>
.kaogu_box {
    overflow: hidden;
    position: absolute;
    z-index: 9999;
    top: 0;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    padding: 6vw;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);

    .centent {
        position: relative;
        background: url('../imager/data/kaogu_back.png') no-repeat;
        background-size: 100%;
        height: 970px;
        width: 100%;
        margin-top: -200px;


    }

    .centent_boxtitle {
        width: 100%;
        height: 50px;
        position: absolute;
        // background: salmon;
        align-items: center;
        text-align: center;
        top: 35px;
        font-weight: 550;
        font-size: 32px;
        background: url('../imager/index/title.png');
        background-position: 20px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        letter-spacing: 8px;
    }

    .centent_box {
        width: 100%;
        height: 750px;
        position: absolute;
        // border: 1px solid red;
        top: 120px;
        box-sizing: border-box;
        padding: 0 10px;

        .box_centent {
            overflow-y: auto;
            // border: 1px solid red;
            height: 100%;
            box-sizing: border-box;
            padding: 0 20px;

            .information {
                margin-top: 30px;
                width: 100%;
                // height: 309px;

                .title {
                    margin-top: 40px;
                    text-align: center;
                    width: 242px;
                    color: #e4f7fb;
                    height: 61px;
                    font-size: 25px;
                    line-height: 61px;
                    letter-spacing: 6px;
                    font-weight: 500;
                    background-image: url('../imager/data/title2.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }

                table {
                    margin-top: 20px;
                    margin-left: 5px;
                    width: 98%;
                    border-spacing: 0;
                    border-collapse: collapse;
                    font-size: 18px;
                }

                .left {
                    height: 80px;
                    width: 20%;
                    margin-top: -5px;
                    border: 3px solid #6fd9f1;
                    text-align: center;
                    color: #eefcff;
                    letter-spacing: 5px;
                    font-weight: 550;

                }

                .row {
                    padding: 20px;
                    text-indent: 40px;
                    text-align: left;
                }

                .right {
                    width: 20%;
                    letter-spacing: 5px;
                    border: 1px solid #6fd9f1;
                    text-align: center;
                    color: #eefcff;

                }
            }

            // 考古现场发现
            .information2 {
                width: 100%;
                margin-top: 43px;

                .title {
                    text-align: center;
                    width: 242px;
                    color: #e4f7fb;
                    height: 61px;
                    font-size: 25px;
                    line-height: 61px;
                    letter-spacing: 6px;
                    font-weight: 500;
                    background-image: url('../imager/data/title2.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }

                .look_img {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-flow: row wrap;

                    .img_box {
                        margin-top: 20px;
                        width: 280px;
                        height: 180px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: url('../imager/data/img_border.png') no-repeat;
                        background-size: 100% 100%;
                    }

                    .imgone {
                        position: relative;
                        width: 267px;
                        height: 160px;
                    }

                }


            }
        }
    }
}

.finish {
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 65px;
        // height: 1;
    }
}

// 遮罩
.zhezhao {
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 115px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    img {
        width: 100%;
        // height: 1;
    }
}

::-webkit-scrollbar {
    width: 10px;
    height: 16px;
    border-radius: 25px;
    margin-right: 20px;
    background: #3582aa;
}

/*定义滚动条轨道
//  内阴影+圆角*/
::-webkit-scrollbar-track {
    border-radius: 25px;
    // background-color:#F5F5F5;
    background-color: rgb(0, 0, 0, 0.1);
    // border: 1px solid #81aac9;

}

/*定义滑块
//  内阴影+圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: #21bddf;
    background-color: #21bddf;
}

.kg {
    position: absolute;
    z-index: 9999;
}

.imgcenter {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 600px;
    }
}


// 新加

// 新加
.material_new {
    width: 100%;
    height: 1000px;
    margin-top: 50px;

    .titileone {
        width: 100%;
        height: 40px;
        color: #ffffff;
        font-weight: 500;
        font-size: 30px;
        letter-spacing: 0.8vw;
        // border-bottom: 5px solid #8a453e;

        p {
            display: inline-block;
            width: auto;
            background: #3f4b59;
        }
    }

    .titile {
        margin-top: 20px;
        width: 100%;
        height: 80px;
        text-align: center;
        color: #8a453e;
        font-weight: 550;
        font-size: 45px;
        letter-spacing: 0.8vw;
        border-bottom: 5px solid #8a453e;
    }

    .three {
        margin-top: 40px;
        width: 100%;
        height: 80px;
        text-align: center;
        color: #8a453e;
        font-weight: 550;
        font-size: 30px;
        letter-spacing: 0.8vw;

        p {
            background: #77818c;
            display: inline-block;
            width: auto;
            color: white;
            height: 50px;
            // border-radius: 10px;
            line-height: 50px;
            font-size: 18px;

        }
    }
}









.titlebug {
    text-align: center;
    width: 260px;
    color: #e4f7fb;
    height: 61px;
    font-size: 25px;
    line-height: 61px;
    letter-spacing: 6px;
    font-weight: 500;
    background-image: url('../imager/data/title2.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
</style>