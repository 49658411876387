<template>
    <div class="content">
        <!-- 加载页面 -->
        <div :class="this.loading">
            <div class="centent_box">
                <!-- 进度条logo -->
                <div class="bigbox">
                    <div class="progress-bar">
                        <div class="progress-fill"></div>
                    </div>
                </div>

                <!-- 标题 -->
                <div class="title">
                    <img src="../imager/index/loading_name.png" alt="">
                </div>
                <!-- 进度条百分比 -->
                <div class="percentage">
                    {{ bar }}
                </div>
            </div>
        </div>


        <!-- 加入模型 -->
        <div id="obj"></div>



        <!-- 关闭按钮-->
        <!--  v-if="isImgbox" -->
      
        <!-- 俩按钮 -->
        <!-- 显示图片 -->
        <div class="img_bigbox" v-if="isImgbox">
            <div class="img_box">
                <img v-if="isImgboxNumber == 1 && this.pathurl == `n1`" src="../imager/backups/gw.png" class="pan" alt="">
                <img v-if="isImgboxNumber == 2 && this.pathurl == `n1`" src="../imager/backups/gw2.jpeg" class="pan" alt="">
                <!-- 磁盘1 -->
                <img v-if="isImgboxNumber == 1 && this.pathurl == `Y13`" src="../imager/backups/11.jpeg" class="pan2" alt="">
                <img v-if="isImgboxNumber == 2 && this.pathurl == `Y13`" src="../imager/backups/12.jpeg" class="pan2" alt="">
            </div>
            <div class="close">
            <img src="../imager/data/delete.png" alt="" @click="closeBtn()">

        </div>
        </div>

        <!-- 内容太 -->
    </div>
</template>

<script>
// THREE
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment'
// import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'
// import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import anime from '../utils/anime.es'
// THREE
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
export default {
    components: {
    },

    props: ['pathCurl', 'routerpathurl'],
    data() {
        return {
            title: 'Hello',
            active: 0,
            camera: null,
            scene: null,
            renderer: null,
            controls: null,
            object: null,
            renderer: null,
            texture: null,
            texture2: null,
            mesh: null,
            mesh2: null,
            groudp: null,
            spend: 0.005,
            bar: null,
            loading: "loading",
            // 加载背景2
            lodingtwo: false,
            // 定义底部动画
            bottomactive: "none",
            // 文物标题
            cultural_title: "cultural_title",//animate__animated animate__fadeInDown
            // 表格
            index_table: "index_table",// animate__animated animate__fadeInLeftBig
            // 时间线
            time_line: "time_line",//animate__animated animate__fadeInLeftBig
            // 器物简述
            dofi: "dofi",// animate__animated animate__fadeInLeftBig
            // 介绍
            introduce: "introduce",//animate__animated animate__fadeInLeftBig
            // 定义一个旋转速度
            durationList: {
                duration1: 7500,//从外到内第一层旋转一周时间
                duration2: 6500,//从外到内第二层旋转一周时间
                duration3: 5500,//从外到内第三层旋转一周时间
                duration4: 4500,//从外到内第四层旋转一周时间
            },
            groupbig: null,
            pathurl: "Y13",
            frame: null,
            isImgbox: false,
			isImgboxNumber: null,

        }
    },
    computed: {
        ...mapGetters(['getId']),
        ...mapState(['stotitle']),
        ...mapState(['briefntroduction']),
        ...mapState(['time'])


    },

    created() {
        if (this.routerpathurl) {
            // console.log('内层函数')
            this.loading = 'loadingnone';
        }
    },

    methods: {
        // 模型点击
        onMouseDown2(event) {
            // 获取鼠标坐标
            const mouse = new THREE.Vector2();
            mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
            mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

            // 将鼠标坐标转换为Three.js场景中的坐标
            const raycaster = new THREE.Raycaster();
            raycaster.setFromCamera(mouse, this.camera);

            // 检测射线与模型的交点
            const intersects = raycaster.intersectObjects(this.scene.children, true);
            if (intersects.length > 0) {
                // 点击到模型了
                const object = intersects[0].object;
                console.log(object.name)
                // 标记1
                if (object.name == 1) {
                    // 开启图片
                    this.isImgbox = true;
					this.isImgboxNumber = 1;
					this.mesh.visible = false;
					this.sprite.visible = false;
					this.sprite1.visible = false;
					this.sprite2.visible = false;
					this.groudp.visible = false;
                }
                // 标记2
                if (object.name == 2) {
                    this.isImgbox = true;
					this.isImgboxNumber = 2;
                    this.mesh.visible = false;
					this.sprite.visible = false;
					this.sprite1.visible = false;
					this.sprite2.visible = false;
					this.groudp.visible = false;
                    this.groupbig.visible=false;

                }
                // 标记3
                if (object.name == 3) {
                    alert("3")
                }
                // do something
            }
        },
        onMouseDown() {
            this.spend = 0
        },
        onMouseUp() {
            this.spend = 0.005
        },
        btnclick(e) {
            this.active = e
            // console.log(e)

        },
        init(k) {
            const container = document.createElement('div')
            const objcent = document.getElementById('obj')
            objcent.appendChild(container)
            this.scene = new THREE.Scene()
            let scene = this.scene
            this.camera = new THREE.PerspectiveCamera(
                65,
                window.innerWidth / window.innerHeight,
                0.1,
                200
            )
            this.camera.position.set(-0.75, 0.7, 1.25)
            this.camera.lookAt(this.scene.position)
            this.camera.add(new THREE.PointLight(0xffffff, 1));
            // this.scene.add(this.camera);

            //材质
            const ciqimat9 = new THREE.MeshStandardMaterial()
            let loadingManager = new THREE.LoadingManager();
            const ciqi9 = new OBJLoader(loadingManager)
            this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, logarithmicDepthBuffer: true })
            // this.renderer.setClearAlpha(0);
            this.renderer.setPixelRatio(window.devicePixelRatio)
            this.renderer.setSize(window.innerWidth, window.innerHeight)
            // this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
            // this.renderer.toneMappingExposure = 1
            this.renderer.outputEncoding = THREE.sRGBEncoding;
            // 添加底部贴纸

            this.groupbig = new THREE.Group();

            this.texture = new THREE.TextureLoader().load('1.png');
            this.texture.encoding = THREE.sRGBEncoding;
            this.texture.center.set(0.01, 0.5);
            let material = new THREE.MeshBasicMaterial({
                map: this.texture,
                transparent: true,
                // 旋转可不显示
                side: THREE.DoubleSide
            });

            let geometry = new THREE.PlaneGeometry(0.5, 0.5).rotateX(-Math.PI / 2);
            this.mesh = new THREE.Mesh(geometry, material);
            // this.mesh.scale.set(0, 0, 0)
            this.mesh.scale.set(1, 1, 1)
            // 底部贴片
            this.mesh.position.set(0, -0.05, 0);
            this.groupbig.add(this.mesh);
            anime({
                targets: this.mesh.rotation,//最基本的动画写法
                y: Math.PI * 2, //让光环旋转360度
                duration: this.durationList.duration1,//动画播放时间
                easing: "linear",//根据gif图,最外圈是匀速旋转,这里使用linear
                loop: true//gif图中此处为无限循环
            })
            this.texture2 = new THREE.TextureLoader().load('2.png');
            this.texture2.encoding = THREE.sRGBEncoding;
            this.texture2.center.set(0.5, 0.5);
            let material2 = new THREE.MeshBasicMaterial({
                map: this.texture2,
                transparent: true,
                // 旋转可不显示
                side: THREE.DoubleSide
            });
            this.mesh2 = new THREE.Mesh(geometry, material2);
            this.mesh2.scale.set(1, 1, 1)
            // this.mesh.position.set(0, -0.051, 0);
            this.mesh2.position.y += 0.00001;
            this.mesh2.position.set(0, -0.051, 0);

            this.groupbig.add(this.mesh2);
            this.groupbig.add(new THREE.AmbientLight(0xffffff, 0.8));//创建一个亮度为0的灯光等于没创建
            anime({
                targets: this.mesh2.rotation,//最基本的动画写法
                y: Math.PI * 2, //让光环旋转360度
                duration: this.durationList.duration2,//动画播放时间
                easing: "linear",//根据gif图,最外圈是匀速旋转,这里使用linear
                loop: true//gif图中此处为无限循环
            })
            this.texture3 = new THREE.TextureLoader().load('3.png');
            this.texture3.center.set(0.5, 0.5);
            let material3 = new THREE.MeshBasicMaterial({
                map: this.texture3,
                transparent: true,
                // 旋转可不显示
                side: THREE.DoubleSide
            });
            this.mesh3 = new THREE.Mesh(geometry, material3);
            this.mesh3.scale.set(1, 1, 1)
            // this.mesh.position.set(0, -0.051, 0);
            this.mesh3.position.set(0, -0.0511, 0);

            this.mesh3.position.y += 0.0002
            // scene.add(this.mesh3);
            this.texture4 = new THREE.TextureLoader().load('4.png');
            this.texture4.center.set(1.5, 1.5, 1.5)
            let material4 = new THREE.MeshBasicMaterial({
                map: this.texture4,
                transparent: true,
                // 旋转可不显示
                side: THREE.DoubleSide
            });
            this.mesh4 = new THREE.Mesh(geometry, material4);
            this.mesh4.scale.set(1, 1, 1)
            this.mesh4.position.set(0, -0.053, 0);

            // this.mesh.position.set(0, -0.051, 0);
            this.mesh4.position.y += 0.0003
            this.groupbig.add(this.mesh4);
            anime({
                targets: this.mesh4.rotation,//最基本的动画写法
                y: Math.PI * 2, //让光环旋转360度
                duration: this.durationList.duration4,//动画播放时间
                easing: "easeOutQuart",//根据gif图,最外圈是匀速旋转,这里使用linear
                loop: true,
                direction: "alternate",
                delay: 100,//gif图中此处为无限循环
            })

            this.texture5 = new THREE.TextureLoader().load('5.png');
            this.texture5.center.set(1.5, 1.5, 1.5)
            let material5 = new THREE.MeshBasicMaterial({
                map: this.texture5,
                transparent: true,
                // 旋转可不显示
                side: THREE.DoubleSide
            });
            this.mesh5 = new THREE.Mesh(geometry, material5);
            this.mesh5.scale.set(1, 1, 1)
            // this.mesh.position.set(0, -0.051, 0);
            this.mesh5.position.set(0, -0.054, 0);

            this.mesh5.position.y += 0.0004
            this.groupbig.add(this.mesh5);
            anime({
                targets: this.mesh5.rotation,//最基本的动画写法
                y: Math.PI * 2, //让光环旋转360度
                duration: this.durationList.duration5,//动画播放时间
                easing: "linear",//根据gif图,最外圈是匀速旋转,这里使用linear
                loop: true//gif图中此处为无限循环
            })
            this.texture6 = new THREE.TextureLoader().load('6.png');
            this.texture6.center.set(1, 1, 1)
            let material6 = new THREE.MeshBasicMaterial({
                map: this.texture6,
                transparent: true,
                // 旋转可不显示
                side: THREE.DoubleSide
            });
            this.mesh6 = new THREE.Mesh(geometry, material6);
            this.mesh6.scale.set(1, 1, 1)
            // this.mesh.position.set(0, -0.051, 0);
            this.mesh6.position.set(0, -0.055, 0);

            this.mesh6.position.y += 0.0005
            this.groupbig.add(this.mesh6);
            this.texture7 = new THREE.TextureLoader().load('7.png');
            this.texture7.center.set(0.5, 0.5);
            let texture7 = new THREE.MeshBasicMaterial({
                map: this.texture7,
                transparent: true,
                // 旋转可不显示
                side: THREE.DoubleSide
            });
            this.mesh7 = new THREE.Mesh(geometry, texture7);
            this.mesh7.scale.set(1, 1, 1)
            // this.mesh.position.set(0, -0.051, 0);
            this.mesh7.position.set(0, -0.056, 0);

            this.mesh7.position.y += 0.0006
            this.groupbig.add(this.mesh7);

            this.groupbig.scale.set(0.01, 0.01, 0.01)
            this.scene.add(this.groupbig)
            // 贴片 如果是第二个页面直接不显示
            if (this.routerpathurl) {
                var spriteMap = new THREE.TextureLoader().load("btnico.png");
                var spriteMaterial = new THREE.SpriteMaterial({ map: spriteMap });
                this.sprite = new THREE.Sprite(spriteMaterial);
                this.sprite.position.set(-0.36, 0.15, 0);
                this.sprite.scale.set(0.08, 0.08, 0.08);
                this.sprite.name = "1"
                this.groupbig.add(this.sprite);
                this.sprite1 = new THREE.Sprite(spriteMaterial);
                this.sprite1.position.set(-0.09, 0.15, -0.38);
                this.sprite1.scale.set(0.08, 0.08, 0.08);
                this.sprite1.name = "2"
                this.groupbig.add(this.sprite1);
                this.sprite2 = new THREE.Sprite(spriteMaterial);
                this.sprite2.name = "3"

                console.log(this.pathurl, "pathurl")
                if (this.pathurl == "n1") {

                    this.sprite2.position.set(0.36, 0.38, 0);
                }
                if (this.pathurl == "Y13") {

                    this.sprite2.position.set(0.36, 0.23, 0);

                }

                this.sprite2.scale.set(0.08, 0.08, 0.08);
                // this.scene.add(this.sprite2);

                // this.groupbig.add(this.sprite2);

            }







            // 结束`${that.pathurl}.obj`
            let that = this;
            ciqi9.setPath('models/zl2/').load(`${this.pathurl}1.obj`, (group) => {
                const loader = new THREE.TextureLoader(loadingManager).setPath('models/zl2/')
                // 光滑度
                // ciqimat9.roughness = 0.5;
                const diffuseMap = loader.load(`${this.pathurl}k1.jpg`)
                diffuseMap.encoding = THREE.sRGBEncoding
                ciqimat9.map = diffuseMap
                ciqimat9.map.wrapS = THREE.RepeatWrapping
                ciqimat9.normalMap = loader.load(`${this.pathurl}2.jpg`)
                ciqimat9.normalMap.wrapS = THREE.RepeatWrapping
                // ciqimat9.roughnessMap = loader.load(`zjyp_gloss.jpg`)
                ciqimat9.roughnessMap = loader.load(`${this.pathurl}3.jpg`)
                ciqimat9.roughnessMap.wrapS = THREE.RepeatWrapping


















                group.traverse(function (child) {
                    if (child.isMesh) {
                        child.material = ciqimat9
                    }
                })
                that.groudp = group;
                if (this.pathurl == "Y13") {
                    that.groudp.position.set(0, 0.02, 0);
                    that.groudp.scale.set(0.9, 0.9, 0.9)
                }
                if (this.pathurl == "n1") {
                    that.groudp.position.set(0, 0.15, 0);
                    that.groudp.scale.set(0.9, 0.9, 0.9)
                }
                that.groupbig.add(that.groudp)
            }, function (xhr) {
                that.bar = Math.floor((xhr.loaded / xhr.total) * 100) + '%'
                // 获取进度条子元素
                var progressFill = document.querySelector('.progress-fill');
                // 模拟进度的更新
                progressFill.style.height = that.bar;

            })
            //    加载完成
            loadingManager.onLoad = (url, itemsLoaded, itemsTotal) => {

                setTimeout(
                    function () {
                        that.$emit('numChange','none')
                        // console.log('内层函数')
                        that.loading = 'loadingnone';
                    }, 500)
               
                this.btns()
            }
            this.scene.background = null;
            container.appendChild(this.renderer.domElement)
            // 添加纹理贴图
            const environment = new RoomEnvironment()
            // const pmremGenerator = new THREE.PMREMGenerator(this.renderer)
            // this.scene.environment = pmremGenerator.fromScene(environment).texture
            //控制器
            this.controls = new OrbitControls(this.camera, this.renderer.domElement)
            this.controls.enableDamping = true
            this.controls.minDistance = 1
            this.controls.maxDistance = 2
            this.controls.enablePan = false;
            this.controls.update()
            this.renderer.domElement.addEventListener('click', this.onMouseDown2);
            window.addEventListener('resize', this.onWindowResize)
        },
        onWindowResize() {
            this.camera.aspect = window.innerWidth / window.innerHeight
            this.camera.updateProjectionMatrix()
            this.renderer.setSize(window.innerWidth, window.innerHeight)
        },
        // 点击模型变化
        btns() {
            // console.log(this.groudp)
            //方法2扩展
            let param2 = {
                // px: this.groudp.position.x,
                // ry: this.groudp.rotation.y,
                scale: 0.2,//一般默认为1,  sx:scale.x,但是一般我们不单独操作单方向的缩放值
                meshscale: 1
            };
            anime({
                targets: param2,
                // px:10,//5秒时间沿着x轴平移10个单位
                // ry: Math.PI * 2,//5秒时间沿着y轴正方向旋转360度
                scale: 1.8,//5秒时间缩小0.5
                duration: 3500,
                easing: "easeOutCubic",
                update: () => {

                    // mesh3.scale.setScalar(param2.scale);

                    //使用这样的动画,可以同时控制不同的物体
                    // mesh3.rotation.y = param2.ry;
                    this.groupbig.scale.setScalar(param2.scale);

                    // this.mesh.scale.setScalar(param2.scale);
                }
            });



            //方法2扩展
            let param22 = {
                scale: 1,//一般默认为1,  sx:scale.x,但是一般我们不单独操作单方向的缩放值
            };
        },
        animate() {
            this.groupbig.rotation.y += this.spend
            this.controls.update() // required if damping enabled
            this.render(this.scene, this.camera)
            this.mesh.rotation.y += 0.01;
            this.frame = requestAnimationFrame(this.animate)

        },
        render() {
            this.renderer.render(this.scene, this.camera)
        },
        ...mapActions(['updateId']),
        // 关闭显示图片区域
        closeBtn() {
            this.isImgbox = false;
            this.mesh.visible = true;
            this.sprite.visible = true;
            this.sprite1.visible = true;
            this.sprite2.visible = true;
            this.groudp.visible = true;
            this.groupbig.visible=true;

        }
    },

    mounted() {

        let id = this.$route.params.newsId;
        console.log(id, "ID", typeof (id))
        let ids = localStorage.getItem("id");
        if (id !== "1" && id !== "2" && id !== "3" && id !== "4" && id !== "5") {
            // console.log("参数不的")
            this.updateId(ids);
        } else {
            this.updateId(id);
        }

        let ids1 = localStorage.getItem("id");
        console.log(ids1)
        if (!ids1) {
            ids1 = id;
            console.log('首次进入没有缓存，用url中的id代替');
        }
        let k = null
        if (ids1 == 1) {
            this.pathurl = "Y13"
            k = "Y13"
        }
        if (ids1 == 2) {
            this.pathurl = "n1"
            k = "n1"
        }
        if (ids1 == 3) {
            this.pathurl = "Y181"
            k = "Y181"
        }
        if (ids1 == 4) {
            this.pathurl = "Y381"
            k = "Y381"

        }
        if (ids1 == 5) {
            this.pathurl = "Y402"
            k = "Y402"
        }
        this.init(k)
        this.animate()
    },

    beforeDestroy() {
        cancelAnimationFrame(this.frame)
        console.log(this.frame)
        //这里要倒着遍历
        console.log("scene.children", this.scene.children)
        for (let i = this.scene.children.length - 1; i >= 0; i--) {
            let children = this.scene.children[i];
            console.log(children.isMesh)
            if (children.isMesh) {
                disposeMesh(children);
                this.scene.remove(children)
            } else {
                if (children.children.length > 0) {
                    disposeGroup(children);
                }
                this.scene.remove(children);
            }
        }

        // //清理带有子项的物体,如Group和Object3D
        function disposeGroup(group) {
            for (let i = 0; i < group.children.length; i++) {
                let children = group.children[i];

                if (children.isMesh) {
                    disposeMesh(children);
                    console.log(children, "childrenchildrenchildrenchildren")

                } else {
                    if (children.children.length > 0) {
                        disposeGroup(children);

                    }
                }
            }
        }

        // //清理Mesh
        function disposeMesh(mesh) {
            mesh.geometry.dispose();
            disposeMaterial(mesh.material);

        }

        // //清理材质
        function disposeMaterial(material) {
            for (let key in material) {
                if (key.indexOf('map') !== -1) {
                    let texture = material[key];
                    material[key] = null;
                    disposeTexture(texture);
                }
            }

            material.dispose();

        }

        // //清理texture
        function disposeTexture(texture) {
            if (texture.isTexture) {
                texture.dispose();
            }
        }

        // //清理渲染器
        this.renderer.dispose()
        console.log(this.renderer)
        this.renderer.forceContextLoss()
    }

}
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
}

.content {
    width: 100%;
    min-height: 100vh;
}

.index_bcl {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    img {
        width: 100%;
        height: 100vh;
    }

    .img1 {
        position: relative;
        top: 0;
        left: 0;
        z-index: -1;
        pointer-events: none;

    }

    .img2 {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
        pointer-events: none;
    }
}

// 底部栏
.ceneten {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
}

// 器物描述
.dofi {
    width: 100%;
    height: 150px;
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 80px;

    img {
        height: 100px;
        width: 290px;
        margin-top: -40px !important;
    }
}

// 文物标题
.cultural_title {
    position: absolute;
    top: 75px;
    width: 100%;
    height: 180px;
    background: url('../imager/index/title.gif') no-repeat;
    background-size: 100% 121%;
    background-position-y: -55px;

    .chinese_title {
        font-size: 48px;
        display: inline-block;
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        font-weight: 650;
        letter-spacing: 10px;
        color: #f5fafe;
        background: url('../imager/index/title.png');
        background-size: 100% 100%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .english_title {
        display: inline-block;
        width: 100%;
        height: 30px;
        float: left;
        display: flex;
        justify-content: center;
        margin-top: 10px;
        font-size: 20px;
        color: #88aed3;
    }
}

// 首页表格
.index_table {
    width: 100%;
    // height: 120px;
    position: absolute;
    top: 230px;
    display: flex;
    justify-content: center;

    .index_table_yab {
        position: relative;
        margin-left: 51px;
        margin-right: 51px;
        // border: 1px solid #517995;
        border-bottom: 0;
        overflow: hidden;

    }

    table {

        // width: 600px;
        border-collapse: collapse;
        text-align: center;
        font-size: 23px;
        color: #d3f3ff;
        letter-spacing: 5px;
        background: #07355a;

        &::before {
            position: absolute;
            top: -2px;
            left: -2px;
            content: "";
            width: 10px;
            height: 10px;
            border-top: 5px solid #dff8fd;
            border-left: 5px solid #dff8fd;
        }

        &::after {
            position: absolute;
            top: -2px;
            right: -2px;
            content: "";
            width: 10px;
            height: 10px;
            border-top: 5px solid #dff8fd;
            border-right: 5px solid #dff8fd;
        }


    }

    .index_table_yab .top {
        top: 0;
        right: -100%;
        background-image: linear-gradient(270deg,
                transparent,
                #03e9f4,
                transparent);
        animation: three 4s linear 3s infinite;
    }


    .index_table_yab::before {
        content: " ";
        position: absolute;
        width: 1px;
        height: 100%;
        top: -100%;
        left: 0;
        background-image: linear-gradient(0deg,
                transparent,
                #03e9f4,
                transparent);
        animation: two 4s linear infinite;
    }

    .index_table_yab::after {
        content: " ";
        position: absolute;
        width: 1px;
        height: 100%;
        bottom: -100%;
        right: 0;
        background-image: linear-gradient(360deg,
                transparent,
                #03e9f4,
                transparent);
        animation: four 4s linear 2s infinite;
    }

    .index_table_yab i {
        position: absolute;
        display: inline-block;
        height: 1px;
        width: 100%;
    }

    .index_table_yab .bottom {
        bottom: 0;
        left: -100%;
        background-image: linear-gradient(270deg,
                transparent,
                #03e9f4,
                transparent);
        animation: one 4s linear 1s infinite;
    }

    @keyframes one {
        0% {
            left: -100%;
        }

        50%,
        100% {
            left: 100%;
        }
    }

    @keyframes xs {
        0% {
            transform: scale(1);
        }

        25% {
            transform: scale(1.1);
        }

        50% {
            transform: scale(1.2);
        }

        75% {
            transform: scale(1.1);
        }

        100% {
            transform: scale(1);
        }
    }

    @keyframes two {
        0% {
            top: -100%;
        }

        50%,
        100% {
            top: 100%;
        }
    }

    @keyframes three {
        0% {
            right: -100%;
        }

        50%,
        100% {
            right: 100%;
        }
    }

    @keyframes four {
        0% {
            bottom: -100%;
        }

        50%,
        100% {
            bottom: 100%;
        }
    }

    thead {
        height: 55px;
        border-collapse: collapse;
        // border: 1px solid #517995;
        // background: #01548f;
        padding: 10px;

        tr {
            td {

                div {
                    background: #01548f;
                    margin: 0 3px;
                    height: 44px;
                    line-height: 42px;
                    padding: 0 15px;
                    margin: 5px;
                }

                div:nth-child(2) {
                    background: #01548f;
                    margin: 0 1px;
                    height: 44px;
                    line-height: 42px;
                }
            }
        }
    }

    tbody {
        border-top: 0px solid;

        tr {
            td {

                div {
                    padding: 14px 10px;
                }
            }
        }
    }

    td {
        border: 1px solid #517995;



    }

    .panel-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;

        &::before {
            position: absolute;
            bottom: 0;
            left: -2px;
            content: "";
            width: 10px;
            height: 10px;
            border-bottom: 5px solid #dff8fd;
            border-left: 5px solid #dff8fd;
        }

        &::after {
            position: absolute;
            bottom: 0;
            right: -2px;
            content: "";
            width: 10px;
            height: 10px;
            border-bottom: 5px solid #dff8fd;
            border-right: 5px solid #dff8fd;
        }
    }
}

// 时间线
.time_line {
    width: 100%;
    height: 114px;
    position: absolute;
    bottom: 370px;

    .time_p {
        color: #c7e9fa;
        position: absolute;
        right: 120px;
        top: -50px;
        font-size: 32px;
    }

    .anborder {
        width: 2.33333vw;
        height: 2.33333vw;
        display: inline-block;
        // background: #88f2ff;
        background: white;
        border-radius: 50%;
        position: absolute;
        right: 26vw;
        top: 1.33333vw;
        animation: xs 2s linear 1s infinite;
        opacity: 0.85;
    }

    img {
        width: 100%;
    }

    .line {
        width: 100%;

        .line_border {
            width: 100%;
        }

        position: absolute;
        top: -88px;
    }
}

//  介绍
.introduce {
    width: 100%;
    position: absolute;
    bottom: 170px;
    height: 200px;
    background: url('../imager/index/text.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;

    .cententext {
        width: 96%;
        height: 100px;
        color: #f1faff;
        overflow-y: scroll;
        margin-top: 30px;
        font-size: 25px;
        text-indent: 40px;
        letter-spacing: 2px;
        line-height: 35px;
        display: flex;
        justify-content: center;

        div {
            width: 93%;
        }
    }

}

::-webkit-scrollbar {
    width: 16px;
    height: 16px;
    border-radius: 25px;
    // background:#F5F5F5;
}

/*定义滚动条轨道
//  内阴影+圆角*/
::-webkit-scrollbar-track {
    border-radius: 25px;
    // background-color:#F5F5F5;
    background-color: rgb(0, 0, 0, 0.1);
    border: 1px solid #81aac9;

}

/*定义滑块
//  内阴影+圆角*/
::-webkit-scrollbar-thumb {
    height: 40px;
    border-radius: 10px;
    -webkit-box-shadow: #21bddf;
    background-color: #21bddf;
}


#obj {
    position: relative;

}




//加载页面
.loading {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 9999999;
    // opacity: 0.5;
    pointer-events: all;
    background-image: url('../imager/index/loading_bck.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    //  中间盒子
    .centent_box {
        width: 100%;
        height: 700px;
        // background: red;
        z-index: -4;
        display: flex;
        justify-content: center;

    }

    //   logo进度条
    .progress-bar {
        width: 115px;
        height: 170px;
        background-image: url('../imager/index/logo1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: relative;
    }

    .progress-fill {
        width: 100%;
        height: 0%;
        background-image: url('../imager/index/bar-fill.png');

        background-repeat: no-repeat;
        background-position: bottom;
        position: absolute;
        background-size: 100%;
        z-index: -1;
        bottom: 1.7px;
        /* left: -1px; */
        transition: height 0.5s ease-in-out;
    }

    .bigbox {
        position: absolute;
        width: 115px;
        height: 170px;
        z-index: -3;
        // background: black;
    }

    // 百分比
    .percentage {
        width: 100%;
        height: 65px;
        position: absolute;
        // background: red;
        display: flex;
        justify-content: center;
        margin-top: 400px;
        font-size: 35px;
        letter-spacing: 4px;
        font-weight: 600;
        color: rgb(83, 186, 248);
    }


    // 标题
    .title {
        width: 100%;
        height: 65px;
        position: absolute;
        // background: red;
        display: flex;
        justify-content: center;
        margin-top: 280px;

        img {
            height: 100%;
        }
    }
}

// 隐藏
.loadingnone {
    display: none;
}

// 图片加载
.jiazai {
    display: none;

}


// 流光
.line_border {
    /* border: 1px solid rgba(32, 254, 255, 0.3); */
    color: #36cee9;
    ;
    position: relative;
    width: 400px;
    height: 20px;
    border: 20px;
    overflow: hidden;
    margin: 200px auto;
}

.line_border::before {
    content: " ";
    position: absolute;
    width: 200%;
    height: 7px;
    border: 7px;
    top: 0;
    left: -100%;
    background-image: linear-gradient(90deg,
            transparent,
            #36cee9,
            transparent);
    animation: flow 6s linear infinite;
}

@keyframes flow {
    0% {
        transform: translateX(100%);
    }

    50% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}


// 贴片
.close {
	width: 100%;
	height: 7.66667vw;
	position: relative;
	bottom:-300px;
	display: flex;
	justify-content: center;
	// align-items: center;
	z-index: 99999;
    position: relative;
	img {
		width: 65px;
	}
}
// 详情图片框
.img_bigbox {
	width: 100%;
	height: 100vh;
    position: relative;
    top: 0;
	display: flex;
	align-items: center;
	position: absolute;
}

.img_box {
	position: absolute;
	box-sizing: border-box;
	//  padding: 0px 20px;
	height: 504px;
	//  background: red;
	width: 100%;
	overflow: hidden;
	background: url('../imager/panoramic/img_border.png') no-repeat;
	background-size: 100% 100%;
	display: flex;
	align-items: center;
   justify-content: center;
	.pan_tao {
		width: 100%;
		height: 100%;
		position: relative;
		padding: 0px 20px;
		box-sizing: border-box;

	}

	.pan {
		box-sizing: border-box;
		width: 90%;
		padding: 10px 20px;
	}
	.pan2 {
		box-sizing: border-box;
		width: 80%;
		padding: 10px 20px;
	}
}

</style>
