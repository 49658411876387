<template>
    <div class="kaogu_box">
        <div class="centent">
            <!--内容区域 -->
            <div class="centent_boxtitle">
                考古
            </div>
            <div class="centent_box">
                <div class="box_centent">
                    <!-- 基本信息 -->
                    <div class="information">
                        <div class="title">
                            考古基本信息
                        </div>
                        <table border="1">
                            <tr>
                                <td class="left">器物名称</td>
                                <td class="right">{{stotitle}}</td>
                            </tr>
                           
                            <tr>
                                <td class="left">发掘时间</td>
                                <td class="right">2002-2004年</td>
                            </tr>
                            <tr>
                                <td class="left">发掘地点</td>
                                <td class="right">景德镇明清御窑厂遗址</td>
                            </tr>
                             <tr>
                                <td class="left">探方地层号</td>
                                <td class="right">IT0603②b</td>
                            </tr>
                            <tr>
                                <td class="left">堆积层次</td>
                                <td class="right">土色红褐，质地疏松。厚0—60厘米，深45—120厘米。属二次搬运填土层。</td>
                            </tr>

                        </table>
                    </div>
                    <!-- 考古现场发现 -->
                    <div class="information2">
                        <div class="title">
                            考古基本信息
                        </div>
                        <!-- 展示图片 -->
                        <div class="look_img">
                            <div class="img_box" v-for="(item, index) in imgdata" :key="index">
                                <img class="imgone" :src="item.img" alt="">
                            </div>
                        </div>

                    </div>

                    <!-- 结束 -->
                </div>
            </div>
            <!-- 遮罩 -->
            <div class="zhezhao">
                <img src="../imager/data/zhezhao.png" alt="">
            </div>
            <!-- 结束按钮 -->
            <div class="finish" @click="btnblock">
                <img src="../imager/data/delete.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
export default {
    data() {
        return {
            imgdata: [
                { img: require("../imager/test/1.png") },
                { img: require("../imager/test/2.png") },
                { img: require("../imager/test/3.png") },
                // { img: require("../imager/test/4.png") }
            ],
            count:false
        };
    },
    computed:{
		...mapState(['stotitle']),
    },
    mounted() {

    },

    methods: {
        btnblock(){
            console.log("我被触发了")
            this.$emit('numChange',this.count)
        }
    },
};
</script>

<style lang="scss" scoped>
.kaogu_box {
    overflow: hidden;
    position: absolute;
    z-index: 9999;
    top: 0;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    padding: 6vw;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    .centent {
        position: relative;
        background: url('../imager/data/kaogu_back.png') no-repeat;
        background-size: 100%;
        height: 970px;
        width: 100%;
        margin-top: -200px;

    }

    .centent_boxtitle {
        width: 100%;
        height: 50px;
        position: absolute;
        // background: salmon;
        align-items: center;
        text-align: center;
        top: 35px;
        font-weight: 550;
        font-size: 32px;
        background: url('../imager/index/title.png');
        background-position: 20px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        letter-spacing: 8px;
    }

    .centent_box {
        width: 100%;
        height: 750px;
        position: absolute;
        // border: 1px solid red;
        top: 120px;
        box-sizing: border-box;
        padding: 0 10px;

        .box_centent {
            overflow-y: auto;
            // border: 1px solid red;
            height: 100%;
            box-sizing: border-box;
            padding: 0 20px;

            .information {
                width: 100%;
                height: auto;

                .title {
                    text-align: center;
                    width: 242px;
                    color: #e4f7fb;
                    height: 61px;
                    font-size: 25px;
                    line-height: 61px;
                    letter-spacing: 6px;
                    font-weight: 500;
                    background-image: url('../imager/data/title2.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }

                table {
                    margin-top: 20px;
                    margin-left: 5px;
                    width: 98%;
                    border-spacing: 0;
                    border-collapse: collapse;
                    font-size: 18px;

                }

                .left {
                    height: 40px;
                    width: 30%;
                    margin-top: -5px;
                    border: 3px solid #6fd9f1;
                    text-align: center;
                    color: #eefcff;
                    letter-spacing: 5px;
                    font-weight: 550;

                }

                .right {
                    width: 65%;
                    letter-spacing: 5px;
                    border: 3px solid #6fd9f1;
                    text-align: center;
                    color: #eefcff;

                }
            }

            // 考古现场发现
            .information2 {
                width: 100%;
                margin-top: 43px;

                .title {
                    text-align: center;
                    width: 242px;
                    color: #e4f7fb;
                    height: 61px;
                    font-size: 25px;
                    line-height: 61px;
                    letter-spacing: 6px;
                    font-weight: 500;
                    background-image: url('../imager/data/title2.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }

                .look_img {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-flow: row wrap;

                    .img_box {
                        margin-top: 20px;
                        width: 100%;
                        height: auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: url('../imager/data/img_border.png') no-repeat;
                        background-size: 100% 100%;
                    }

                    .imgone {
                        position: relative;
                        width: 95%;
                        height: 90%;
                    }

                }


            }
        }
    }
}

.finish {
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 65px;
        // height: 1;
    }
}

// 遮罩
.zhezhao{
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 115px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    img {
        width: 100%;
        // height: 1;
    }
}
::-webkit-scrollbar {
    width: 10px;
    height: 16px;
    border-radius: 25px;
    margin-right: 20px;
    background: #3582aa;
}

/*定义滚动条轨道
//  内阴影+圆角*/
::-webkit-scrollbar-track {
    border-radius: 25px;
    // background-color:#F5F5F5;
    background-color: rgb(0, 0, 0, 0.1);
    // border: 1px solid #81aac9;

}

/*定义滑块
//  内阴影+圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: #21bddf;
    background-color: #21bddf;
}

.kg {
    position: absolute;
    z-index: 9999;
}</style>